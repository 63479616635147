import { Categories, CheckboxOption, EmbedStyleFlags, SelectOptionGroup } from '@shared/interface/common';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { environment } from 'src/environments/environment';

export const proRallyPricingText: string = '$49/month';

export const tippyProperties: NgxTippyProps = {
  allowHTML: true,
  arrow: true,
  theme: 'rs-tooltip',
  interactive: true
};

export const embedDefaults: EmbedStyleFlags = {
  showSummary: true,
  showImage: true,
  showAllActions: true,
  takeMoreActions: false,
  fontFamily: '',
  fontColor: '',
  manualWidth: false,
  width: 0,
  height: 0,
  embedurl: ''
};

export const CATEGORIES: Categories[] = [
  { value: 'all', label: 'All categories', color: '', congress: 'None' },
  { value: 'Animal Rights', label: 'Animal Rights', color: '#50556E', congress: 'Animals' },
  { value: 'Arts & Culture', label: 'Arts & Culture', color: '#4466D0', congress: 'Arts, Culture, Religion' },
  { value: `Children's Issues`, label: `Children's Issues`, color: '#2E294E', congress: 'Families' },
  { value: 'Disaster Relief', label: 'Disaster Relief', color: '#1E3888', congress: 'Emergency Management' },
  { value: 'Economy', label: 'Economy', color: '#076F8A', congress: 'Economics and Public Finance' },
  { value: 'Education', label: 'Education', color: '#EC0B41', congress: 'Education' },
  { value: 'Environment', label: 'Environment', color: '#FFB100', congress: 'Environmental Protection' },
  { value: 'Food & Agriculture', label: 'Food & Agriculture', color: '#4CEFCE', congress: 'Agriculture and Food' },
  { value: 'Health', label: 'Health', color: '#808080', congress: 'Health' },
  { value: 'Human Rights', label: 'Human Rights', color: '#006C67', congress: 'Civil Rights and Liberties, Minority Issues' },
  { value: 'International Aid', label: 'International Aid', color: '#50556E', congress: 'International Affairs' },
  { value: 'LGBTQ Rights', label: 'LGBTQ Rights', color: '#4466D0', congress: 'Civil Rights and Liberties, Minority Issues' },
  { value: 'Media & Entertainment', label: 'Media & Entertainment', color: '#2E294E', congress: 'Arts, Culture, Religion' },
  { value: 'Politics', label: 'Politics', color: '#1E3888', congress: 'Government Operations and Politics' },
  { value: 'Public Safety', label: 'Public Safety', color: '#076F8A', congress: 'Crime and Law Enforcement' },
  { value: 'Social Justice', label: 'Social Justice', color: '#EC0B41', congress: 'Social Welfare' },
  { value: `Veteran's Issues`, label: `Veteran's Issues`, color: '#FFB100', congress: 'Armed Forces and National Security' },
  { value: `Women's Rights`, label: `Women's Rights`, color: '#4CEFCE', congress: 'Civil Rights and Liberties, Minority Issues' }
];

export const INTERESTS_OPTIONS: CheckboxOption[] = CATEGORIES.filter((c) => c.value !== 'all').map(
  (c: Categories) => ({ value: c.value, label: c.label, checked: false } as CheckboxOption)
);

export enum RallyTabs {
  petition = 'Sign Petition',
  boycott = 'Join Boycott',
  pledge = 'Pledge',
  email = 'Email',
  call = 'Call',
  social = 'Tweet',
  online = 'Take Online Action',
  subscribe = 'Subscribe'
}

export enum RallyTypes {
  petition = 'Petition',
  pledge = 'Pledge',
  boycott = 'Boycott',
  email = 'Email Campaign',
  call = 'Call Campaign',
  social = 'Social Media Campaign',
  online = 'Online Action'
}

export const RALLY_TYPE_OPTIONS = [
  {
    icon: 'actions-icon-petition',
    label: RallyTypes.petition,
    description: 'Create a petition and gather signatures to show how many people support your cause.',
    id: 'petition',
    actionLabel: 'petition signed'
  },
  {
    icon: 'actions-icon-pledge',
    label: RallyTypes.pledge,
    description: 'Organize a movement and encourage people to pledge their support.',
    id: 'pledge',
    actionLabel: 'pledge signed'
  },
  {
    icon: 'actions-icon-boycott',
    label: RallyTypes.boycott,
    description: 'Launch a boycott to urge companies to do the right thing.',
    id: 'boycott',
    actionLabel: 'boycott signed'
  },
  {
    icon: 'actions-icon-email',
    label: RallyTypes.email,
    description: 'Send messages straight to decision-makers inboxes.',
    id: 'email',
    actionLabel: 'email sent'
  },
  {
    icon: 'actions-icon-call',
    label: RallyTypes.call,
    description: 'Rally people to call decision-makers and make a compelling ask.',
    id: 'call',
    actionLabel: 'phone call'
  },
  {
    icon: 'actions-icon-social',
    label: RallyTypes.social,
    description: 'Use social media to motivate supporters to tweet decision-makers, recruit more allies, and generate awareness for your cause.',
    id: 'social',
    actionLabel: 'social action'
  },
  {
    icon: 'actions-icon-online-actions',
    label: RallyTypes.online,
    description: 'Direct supporters to take online actions such as writing a review or submitting a message via a contact form.',
    id: 'online',
    actionLabel: 'online action'
  }
];

export const PREFIXES = ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.'];

export const US_STATES = {
  al: 'Alabama',
  ak: 'Alaska',
  as: 'American Samoa',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  dc: 'District Of Columbia',
  fm: 'Federated States Of Micronesia',
  fl: 'Florida',
  ga: 'Georgia',
  gu: 'Guam',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  mh: 'Marshall Islands',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  mp: 'Northern Mariana Islands',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pw: 'Palau',
  pa: 'Pennsylvania',
  pr: 'Puerto Rico',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  vi: 'Virgin Islands',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming'
};

export const customFonts: SelectOptionGroup[] = [
  {
    label: 'Sans serif',
    options: [
      { label: 'ABeeZee', value: 'ABeeZee' },
      { label: 'Abel', value: 'Abel' },
      { label: 'Acme', value: 'Acme' },
      { label: 'Alata', value: 'Alata' },
      { label: 'Alegreya Sans', value: 'Alegreya Sans' },
      { label: 'Almarai', value: 'Almarai' },
      { label: 'Anton', value: 'Anton' },
      { label: 'Archivo', value: 'Archivo' },
      { label: 'Archivo Black', value: 'Archivo Black' },
      { label: 'Archivo Narrow', value: 'Archivo Narrow' },
      { label: 'Arimo', value: 'Arimo' },
      { label: 'Asap', value: 'Asap' },
      { label: 'Asap Condensed', value: 'Asap Condensed' },
      { label: 'Assistant', value: 'Assistant' },
      { label: 'Barlow', value: 'Barlow' },
      { label: 'Barlow Condensed', value: 'Barlow Condensed' },
      { label: 'Barlow Semi Condensed', value: 'Barlow Semi Condensed' },
      { label: 'Cabin', value: 'Cabin' },
      { label: 'Cairo', value: 'Cairo' },
      { label: 'Catamaran', value: 'Catamaran' },
      { label: 'Chakra Petch', value: 'Chakra Petch' },
      { label: 'Changa', value: 'Changa' },
      { label: 'Chivo', value: 'Chivo' },
      { label: 'Didact Gothic', value: 'Didact Gothic' },
      { label: 'DM Sans', value: 'DM Sans' },
      { label: 'Dosis', value: 'Dosis' },
      { label: 'Electrolize', value: 'Electrolize' },
      { label: 'Encode Sans', value: 'Encode Sans' },
      { label: 'Exo', value: 'Exo' },
      { label: 'Exo 2', value: 'Exo 2' },
      { label: 'Fira Sans', value: 'Fira Sans' },
      { label: 'Fira Sans Condensed', value: 'Fira Sans Condensed' },
      { label: 'Fira Sans Extra Condensed', value: 'Fira Sans Extra Condensed' },
      { label: 'Fjalla One', value: 'Fjalla One' },
      { label: 'Francois One', value: 'Francois One' },
      { label: 'Gothic A1', value: 'Gothic A1' },
      { label: 'Heebo', value: 'Heebo' },
      { label: 'Hind', value: 'Hind' },
      { label: 'Hind Madurai', value: 'Hind Madurai' },
      { label: 'Hind Siliguri', value: 'Hind Siliguri' },
      { label: 'IBM Plex Sans', value: 'IBM Plex Sans' },
      { label: 'IBM Plex Sans Arabic', value: 'IBM Plex Sans Arabic' },
      { label: 'Inter', value: 'Inter' },
      { label: 'Josefin Sans', value: 'Josefin Sans' },
      { label: 'Jost', value: 'Jost' },
      { label: 'Kanit', value: 'Kanit' },
      { label: 'Karla', value: 'Karla' },
      { label: 'Lato', value: 'Lato' },
      { label: 'Lexend', value: 'Lexend' },
      { label: 'Libre Franklin', value: 'Libre Franklin' },
      { label: 'M PLUS 1p', value: 'M PLUS 1p' },
      { label: 'M PLUS Rounded 1c', value: 'M PLUS Rounded 1c' },
      { label: 'Manrope', value: 'Manrope' },
      { label: 'Maven Pro', value: 'Maven Pro' },
      { label: 'Merriweather Sans', value: 'Merriweather Sans' },
      { label: 'Michroma', value: 'Michroma' },
      { label: 'Montserrat', value: 'Montserrat' },
      { label: 'Montserrat Alternates', value: 'Montserrat Alternates' },
      { label: 'Mukta', value: 'Mukta' },
      { label: 'Mulish', value: 'Mulish' },
      { label: 'Nanum Gothic', value: 'Nanum Gothic' },
      { label: 'Noto Kufi Arabic', value: 'Noto Kufi Arabic' },
      { label: 'Noto Sans', value: 'Noto Sans' },
      { label: 'Noto Sans Arabic', value: 'Noto Sans Arabic' },
      { label: 'Noto Sans Display', value: 'Noto Sans Display' },
      { label: 'Noto Sans HK', value: 'Noto Sans HK' },
      { label: 'Noto Sans JP', value: 'Noto Sans JP' },
      { label: 'Noto Sans KR', value: 'Noto Sans KR' },
      { label: 'Noto Sans SC', value: 'Noto Sans SC' },
      { label: 'Noto Sans TC', value: 'Noto Sans TC' },
      { label: 'Nunito', value: 'Nunito' },
      { label: 'Nunito Sans', value: 'Nunito Sans' },
      { label: 'Open Sans', value: 'Open Sans' },
      { label: 'Orbitron', value: 'Orbitron' },
      { label: 'Oswald', value: 'Oswald' },
      { label: 'Overpass', value: 'Overpass' },
      { label: 'Oxygen', value: 'Oxygen' },
      { label: 'Padauk', value: 'Padauk' },
      { label: 'Pathway Gothic One', value: 'Pathway Gothic One' },
      { label: 'Paytone One', value: 'Paytone One' },
      { label: 'Philosopher', value: 'Philosopher' },
      { label: 'Play', value: 'Play' },
      { label: 'Poppins', value: 'Poppins' },
      { label: 'Prompt', value: 'Prompt' },
      { label: 'PT Sans', value: 'PT Sans' },
      { label: 'PT Sans Caption', value: 'PT Sans Caption' },
      { label: 'PT Sans Narrow', value: 'PT Sans Narrow' },
      { label: 'Public Sans', value: 'Public Sans' },
      { label: 'Quattrocento Sans', value: 'Quattrocento Sans' },
      { label: 'Questrial', value: 'Questrial' },
      { label: 'Quicksand', value: 'Quicksand' },
      { label: 'Rajdhani', value: 'Rajdhani' },
      { label: 'Raleway', value: 'Raleway' },
      { label: 'Red Hat Display', value: 'Red Hat Display' },
      { label: 'Roboto', value: 'Roboto' },
      { label: 'Roboto Condensed', value: 'Roboto Condensed' },
      { label: 'Rubik', value: 'Rubik' },
      { label: 'Russo One', value: 'Russo One' },
      { label: 'Saira', value: 'Saira' },
      { label: 'Saira Condensed', value: 'Saira Condensed' },
      { label: 'Sarabun', value: 'Sarabun' },
      { label: 'Secular One', value: 'Secular One' },
      { label: 'Signika', value: 'Signika' },
      { label: 'Signika Negative', value: 'Signika Negative' },
      { label: 'Sora', value: 'Sora' },
      { label: 'Source Sans Pro', value: 'Source Sans Pro' },
      { label: 'Space Grotesk', value: 'Space Grotesk' },
      { label: 'Tajawal', value: 'Tajawal' },
      { label: 'Teko', value: 'Teko' },
      { label: 'Titillium Web', value: 'Titillium Web' },
      { label: 'Ubuntu', value: 'Ubuntu' },
      { label: 'Ubuntu Condensed', value: 'Ubuntu Condensed' },
      { label: 'Urbanist', value: 'Urbanist' },
      { label: 'Varela Round', value: 'Varela Round' },
      { label: 'Work Sans', value: 'Work Sans' },
      { label: 'Yanone Kaffeesatz', value: 'Yanone Kaffeesatz' },
      { label: 'Yantramanav', value: 'Yantramanav' }
    ]
  },
  {
    label: 'Display',
    options: [
      { label: 'Abril Fatface', value: 'Abril Fatface' },
      { label: 'Alfa Slab One', value: 'Alfa Slab One' },
      { label: 'Baloo 2', value: 'Baloo 2' },
      { label: 'Bebas Neue', value: 'Bebas Neue' },
      { label: 'Comfortaa', value: 'Comfortaa' },
      { label: 'Concert One', value: 'Concert One' },
      { label: 'Fredoka One', value: 'Fredoka One' },
      { label: 'Lobster', value: 'Lobster' },
      { label: 'Lobster Two', value: 'Lobster Two' },
      { label: 'Luckiest Guy', value: 'Luckiest Guy' },
      { label: 'Passion One', value: 'Passion One' },
      { label: 'Patua One', value: 'Patua One' },
      { label: 'Righteous', value: 'Righteous' },
      { label: 'Titan One', value: 'Titan One' },
      { label: 'Yeseva One', value: 'Yeseva One' }
    ]
  },
  {
    label: 'Serif',
    options: [
      { label: 'Alegreya', value: 'Alegreya' },
      { label: 'Amiri', value: 'Amiri' },
      { label: 'Antic Slab', value: 'Antic Slab' },
      { label: 'Arvo', value: 'Arvo' },
      { label: 'Bitter', value: 'Bitter' },
      { label: 'Bree Serif', value: 'Bree Serif' },
      { label: 'Cardo', value: 'Cardo' },
      { label: 'Cinzel', value: 'Cinzel' },
      { label: 'Cormorant', value: 'Cormorant' },
      { label: 'Cormorant Garamond', value: 'Cormorant Garamond' },
      { label: 'Crete Round', value: 'Crete Round' },
      { label: 'Crimson Text', value: 'Crimson Text' },
      { label: 'DM Serif Display', value: 'DM Serif Display' },
      { label: 'Domine', value: 'Domine' },
      { label: 'EB Garamond', value: 'EB Garamond' },
      { label: 'Eczar', value: 'Eczar' },
      { label: 'Faustina', value: 'Faustina' },
      { label: 'Frank Ruhl Libre', value: 'Frank Ruhl Libre' },
      { label: 'IBM Plex Serif', value: 'IBM Plex Serif' },
      { label: 'Libre Baskerville', value: 'Libre Baskerville' },
      { label: 'Lora', value: 'Lora' },
      { label: 'Marcellus', value: 'Marcellus' },
      { label: 'Martel', value: 'Martel' },
      { label: 'Merriweather', value: 'Merriweather' },
      { label: 'Nanum Myeongjo', value: 'Nanum Myeongjo' },
      { label: 'Noticia Text', value: 'Noticia Text' },
      { label: 'Noto Serif', value: 'Noto Serif' },
      { label: 'Noto Serif JP', value: 'Noto Serif JP' },
      { label: 'Noto Serif KR', value: 'Noto Serif KR' },
      { label: 'Noto Serif TC', value: 'Noto Serif TC' },
      { label: 'Old Standard TT', value: 'Old Standard TT' },
      { label: 'Playfair Display', value: 'Playfair Display' },
      { label: 'Prata', value: 'Prata' },
      { label: 'PT Serif', value: 'PT Serif' },
      { label: 'Roboto Slab', value: 'Roboto Slab' },
      { label: 'Rokkitt', value: 'Rokkitt' },
      { label: 'Sawarabi Mincho', value: 'Sawarabi Mincho' },
      { label: 'Slabo 27px', value: 'Slabo 27px' },
      { label: 'Source Serif Pro', value: 'Source Serif Pro' },
      { label: 'Spectral', value: 'Spectral' },
      { label: 'Tinos', value: 'Tinos' },
      { label: 'Unna', value: 'Unna' },
      { label: 'Vollkorn', value: 'Vollkorn' },
      { label: 'Zilla Slab', value: 'Zilla Slab' }
    ]
  },
  {
    label: 'Handwriting',
    options: [
      { label: 'Amatic SC', value: 'Amatic SC' },
      { label: 'Caveat', value: 'Caveat' },
      { label: 'Cookie', value: 'Cookie' },
      { label: 'Courgette', value: 'Courgette' },
      { label: 'Covered By Your Grace', value: 'Covered By Your Grace' },
      { label: 'Dancing Script', value: 'Dancing Script' },
      { label: 'Gloria Hallelujah', value: 'Gloria Hallelujah' },
      { label: 'Great Vibes', value: 'Great Vibes' },
      { label: 'Indie Flower', value: 'Indie Flower' },
      { label: 'Kalam', value: 'Kalam' },
      { label: 'Kaushan Script', value: 'Kaushan Script' },
      { label: 'Mali', value: 'Mali' },
      { label: 'Pacifico', value: 'Pacifico' },
      { label: 'Patrick Hand', value: 'Patrick Hand' },
      { label: 'Permanent Marker', value: 'Permanent Marker' },
      { label: 'Sacramento', value: 'Sacramento' },
      { label: 'Satisfy', value: 'Satisfy' },
      { label: 'Shadows Into Light', value: 'Shadows Into Light' },
      { label: 'Yellowtail', value: 'Yellowtail' }
    ]
  },
  {
    label: 'Monospace',
    options: [
      { label: 'IBM Plex Mono', value: 'IBM Plex Mono' },
      { label: 'Inconsolata', value: 'Inconsolata' },
      { label: 'Roboto Mono', value: 'Roboto Mono' },
      { label: 'Source Code Pro', value: 'Source Code Pro' },
      { label: 'Space Mono', value: 'Space Mono' }
    ]
  }
];

export enum GovTypes {
  allCongress = 'all-congress',
  onlySenators = 'only-senators',
  onlyRepresentatives = 'only-representatives',
  singleCongress = 'single-congress',
  individualState = 'individual-state',
  individualHouse = 'individual-house'
}

export const allGovernment: string[] = [
  GovTypes.allCongress,
  GovTypes.onlySenators,
  GovTypes.onlyRepresentatives,
  GovTypes.singleCongress,
  GovTypes.individualState,
  GovTypes.individualHouse
];
export const groupGovernment: string[] = [GovTypes.allCongress, GovTypes.onlySenators, GovTypes.onlyRepresentatives];
export const singleGovernment: string[] = [GovTypes.singleCongress, GovTypes.individualState, GovTypes.individualHouse];

export const AI_HELP_TEXT: string =
  'Rally Starter makes it easy to build a campaign by providing you with relevant and compelling messaging to use. ' +
  'Give it a try for generating your campaign summary, email messages, tweet messages, phone scripts and more!' +
  '<br><br><strong>Disclaimer</strong>: At Rally Starter, we are committed to empowering your advocacy efforts. ' +
  'Please note that while this feature is designed to generate high-quality messaging to use for your campaign, the generated content may be outdated, ' +
  'biased or inaccurate. We strongly encourage you to review and validate all generated copy before using it for your campaign.';

export const RALLYBOT_JOKES = [
  { question: 'Why did the advocate bring a pencil to the rally?', answer: 'To draw attention to the need for change!' },
  { question: 'Why did the gardener become a changemaker?', answer: 'To help the grassroots movements grow!' },
  { question: 'Why did the environmentalist bring a plant to the protest?', answer: 'Because they wanted to show their commitment to "rooting" for change!' },
  { question: 'Why did the advocate become a beekeeper?', answer: 'Because they believed in the power of "buzz-worthy" activism!' },
  { question: 'Why did the human rights activist always carry a map?', answer: 'Because they were determined to "chart" a course toward justice!' },
  { question: 'Why did the advocate bring a fishing rod to the rally?', answer: 'Because they were ready to "reel in" supporters for their cause!' },
  { question: 'Why did the advocate start a bakery?', answer: 'Because they wanted to "rise" to the occasion and promote equality one cupcake at a time!' },
  { question: 'Why did the advocate bring a ladder to the protest?', answer: 'Because they wanted to raise awareness!' },
  {
    question: 'How many advocates does it take to change a lightbulb?',
    answer: "Only one, but they'll need to rally support, draft a petition, and hold a candlelight vigil for the old bulb."
  },
  { question: 'Why did the advocacy group start a band?', answer: 'Because they wanted to change the world one chord at a time!' },
  { question: 'Why did the activist go to therapy?', answer: 'Because they needed a safe space to vent about systemic issues.' },
  {
    question: 'Why was the lawyer always so good at advocacy?',
    answer: 'Because they knew how to argue their case in court and in the court of public opinion!'
  }
];

export const RALLYBOT_EMAIL_BODY =
  'I recently discovered Rallybot, a cool, new AI tool from Rally Starter that allows me to mobilize support and engage the community on ' +
  'issues that are important to me.\n\nWith Rallybot, I was able to quickly outline my ideas and transform them into a structured, ' +
  'actionable campaign. Whether you want to start a petition, contact lawmakers, or initiate a boycott, it provides all the necessary tools. ' +
  'It even assists in drafting compelling emails to influential leaders, providing scripts for phone calls, and crafting effective social media ' +
  "posts to gain attention. Try Rallybot to see how you can make your idea for a better world come to life. It's fun and easy to use (it even tells " +
  `dad jokes!). Here's the link: ${environment.siteURL}/rallybot`;
