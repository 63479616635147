<div class="checkbox-input-container">
  <label class="block text-gray-700 text-sm font-bold mb-2 w-full" *ngIf="label">{{ label }} <span *ngIf="requiredField"
      class="required">*</span></label>
  <div>
    <div class="grid gap-x-6 gap-y-4"
      [ngClass]="alignment === 'horizontal' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'">
      <p-checkbox *ngFor="let option of options; index as i" [name]="control" [value]="option.value"
        [binary]="option.value === true || option.value === false" [label]="option.label" [trueValue]="option.value"
        [(ngModel)]="selectedOptions" (ngModelChange)="onChange()">{{ option.label }}</p-checkbox>
    </div>
  </div>
</div>
