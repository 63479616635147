import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SelectOption, SelectOptionGroup } from '@interface/common';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgFor]
})
export class SelectComponent implements OnInit {
  @Input() public parent!: UntypedFormGroup;
  @Input() public control!: string;
  @Input() public options: SelectOption[] | null = [];
  @Input() public optionGroups: SelectOptionGroup[] = [];
  @Input() public placeholder: string;
  @Input() public label: string = '';
  @Input() public required: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public focusOpen: boolean = true;

  @Output() public readonly selected: EventEmitter<any> = new EventEmitter();

  public inputControl: AbstractControl;

  public constructor() {}

  public get hasError(): boolean {
    return this.inputControl.invalid && this.inputControl.dirty;
  }

  public get getErrorMessage(): string {
    if (this.hasError && this.inputControl?.hasError('required')) {
      return 'This field is required.';
    } else if (this.inputControl.hasError('maxlength')) {
      return 'Max character length exceeded';
    } else if (this.inputControl.hasError('file')) {
      return 'Please upload a valid file';
    } else if (this.inputControl.hasError('url')) {
      return 'Please enter a valid URL including http/https protocol';
    } else if (this.inputControl.hasError('email')) {
      return 'Please enter a valid email';
    } else if (this.inputControl.hasError('exists')) {
      return 'Value already exists';
    } else if (this.inputControl.hasError('numeric')) {
      return 'Value needs to be numeric';
    } else if (this.inputControl.hasError('fullName')) {
      return 'Please enter a valid first and last name';
    }
    return 'Please enter a valid input';
  }

  public get requiredField(): boolean {
    return this.inputControl.hasValidator(Validators.required);
  }

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(`Attribute 'control' is required`);
    }

    this.inputControl = this.parent.get(this.control) as AbstractControl;
  }
}
