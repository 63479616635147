<div class="relative w-full" [formGroup]="form">
  <p-autoComplete
    data-hj-allow
    class="w-full"
    styleClass="w-full"
    formControlName="address"
    placeholder="Enter your U.S street address"
    emptyMessage="Address not found. Please enter a valid US address."
    (onSelect)="onSelected($event)"
    optionLabel="description"
    (completeMethod)="onAddressInputChange($event)"
    [suggestions]="options"
    [appendTo]="appendTo"></p-autoComplete>
</div>
