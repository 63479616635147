import { RallyTabs } from './constants';

export const removeHTMLTags = (html: string): string => {
  if (html === null || html === '') {
    return '';
  }

  // replacing </p> tags with line breaks, then replacing the rest with empty string
  return html
    .replace(/(<\/p>)/g, '\n\n')
    .replace(/&amp;/g, '&')
    .replace(/&#8212;|&#8211;/g, '-')
    .replace(/&#8217;/g, `'`)
    .replace(/&#160;/g, ' ')
    .replace(/&#10;/g, '\n\n')
    .replace(/(<([^>]+)>)/gi, '');
};

export const removeURLParams = (url: string): string => {
  const urlObj = new URL(url);
  urlObj.search = '';
  return urlObj.toString();
};

export const getMonthName = (month: number): string => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month];

export const getNextTransitionText = (tabName: string, fromSubscribeTab: boolean = false, isMobile?: boolean): string => {
  let transitionText: string = isMobile
    ? 'The next step is to '
    : `Thanks for ${fromSubscribeTab ? 'subscribing' : 'taking action'}! Now take the next action by `;

  switch (tabName) {
    case RallyTabs.petition:
      transitionText += isMobile ? 'sign a petition!' : 'signing a petition.';
      break;
    case RallyTabs.boycott:
      transitionText += isMobile ? 'join a boycott!' : 'joining a boycott.';
      break;
    case RallyTabs.pledge:
      transitionText += isMobile ? 'sign a pledge!' : 'signing a pledge.';
      break;
    case RallyTabs.email:
      transitionText += isMobile ? 'send an email!' : 'sending an email.';
      break;
    case RallyTabs.call:
      transitionText += isMobile ? 'make a call!' : 'making a call.';
      break;
    case RallyTabs.social:
      transitionText += isMobile ? 'tweet!' : 'tweeting.';
      break;
    case RallyTabs.online:
      transitionText += isMobile ? 'take online action!' : 'taking an online action.';
      break;
    case RallyTabs.subscribe:
      transitionText += isMobile ? 'subscribe!' : 'subscribing.';
      break;
    default:
      transitionText = '';
  }

  return transitionText;
};
