import { Component, inject, OnInit } from '@angular/core';
import { Auth, fetchSignInMethodsForEmail } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';

import { CheckboxModule } from 'primeng/checkbox';
import { NgIf, NgClass } from '@angular/common';
import { InputComponent } from '../input/input.component';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { passwordMatch } from '@shared/utils/validators';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, ReactiveFormsModule, InputComponent, CheckboxModule]
})
export class LoginModalComponent implements OnInit {
  public form: FormGroup;
  public type: 'login' | 'register' = 'login';
  public formLoading: boolean = false;
  public showMigrationAlert: boolean = false;

  private fb: FormBuilder = inject(FormBuilder);
  private authService: AuthService = inject(AuthService);
  private userService: UserService = inject(UserService);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private toasterService: ToastrService = inject(ToastrService);
  private auth: Auth = inject(Auth);
  private dialog: DialogService = inject(DialogService);
  private dialogConfig: DynamicDialogConfig = inject(DynamicDialogConfig);

  public constructor() {
    this.form = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        terms: [false, [Validators.requiredTrue]]
      },
      { validators: passwordMatch }
    );
  }

  public ngOnInit(): void {}

  public get isRegister(): boolean {
    return this.type === 'register';
  }

  public get getActionLabel(): string {
    return this.isRegister ? 'Join' : 'Login';
  }

  public get submitDisabled(): boolean {
    return this.form.invalid || this.formLoading;
  }

  public onToggleView(): void {
    this.type = this.isRegister ? 'login' : 'register';

    if (this.type === 'login') {
      this.form.controls.confirmPassword.setValue('');
      this.form.controls.confirmPassword.clearValidators();
      this.form.controls.terms.setValue(false);
      this.form.controls.terms.clearValidators();
    } else {
      this.form.controls.confirmPassword.setValidators([Validators.required, Validators.minLength(6)]);
      this.form.controls.terms.setValidators(Validators.requiredTrue);
    }

    this.form.controls.confirmPassword.updateValueAndValidity();
    this.form.controls.terms.updateValueAndValidity();

    this.dialogConfig.header = this.isRegister ? 'Signup' : 'Login';
  }

  public onForgotPassword(): void {
    this.dialog
      .open(ForgotPasswordDialogComponent, {
        header: 'Reset Password'
      })
      .onClose.pipe(take(1))
      .subscribe((res) => {
        if (res === 'close') {
          this.dialogRef.close();
        }
      });
  }

  public onLoginWithGoogle(): void {
    this.authService
      .loginWithGoogle()
      .then((res) => {
        this.userService.createOrUpdateUser(res);
        this.dialogRef.close(true);
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(this.auth, error.customData.email as string).then((providers) => {
            if (providers[0] === 'password') {
              this.toasterService.error('It looks like you already have an account with Rally Starter. Please login to continue using Rally Starter!');
            } else {
              this.toasterService.error(
                'It looks like you already have an account with Rally Starter. Please login with your ' +
                  providers[0] +
                  ' account to continue using Rally Starter!'
              );
            }
          });
        }
      });
  }

  public onLoginWithTwitter(): void {
    this.authService
      .loginWithTwitter()
      .then((res) => {
        this.userService.createOrUpdateUser(res);
        this.dialogRef.close(true);
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(this.auth, error.customData.email as string).then((providers) => {
            if (providers[0] === 'password') {
              this.toasterService.error('It looks like you already have an account with Rally Starter. Please login to continue using Rally Starter!');
            } else {
              this.toasterService.error(
                'It looks like you already have an account with Rally Starter. Please login with your ' +
                  providers[0] +
                  ' account to continue using Rally Starter!'
              );
            }
          });
        }
      });
  }

  public onLoginWithFacebook(): void {
    this.authService
      .loginWithFacebook()
      .then((res) => {
        this.userService.createOrUpdateUser(res);
        this.dialogRef.close(true);
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(this.auth, error.customData.email as string).then((providers) => {
            if (providers[0] === 'password') {
              this.toasterService.error('It looks like you already have an account with Rally Starter. Please login to continue using Rally Starter!');
            } else {
              this.toasterService.error(
                'It looks like you already have an account with Rally Starter. Please login with your ' +
                  providers[0] +
                  ' account to continue using Rally Starter!'
              );
            }
          });
        }
      });
  }

  public onSubmit(): void {
    if (this.isRegister && this.form.invalid) {
      this.form.controls.email.markAsTouched();
      this.form.controls.password.markAsTouched();
      this.form.controls.confirmPassword.markAsTouched();
      this.form.controls.terms.markAsTouched();
    }

    if (this.form.valid) {
      this.formLoading = true;
      if (this.isRegister) {
        const email = this.form.value.email.trim();
        const password = this.form.value.password;
        if (this.form.value.password === this.form.value.confirmPassword) {
          this.authService
            .register(email, password)
            .then((res) => {
              this.userService.createOrUpdateUser(res);
              this.dialogRef.close(true);
            })
            .catch((error) => {
              this.formLoading = false;
              if (error.code === 'auth/email-already-in-use') {
                this.form.controls.email.setErrors({
                  custom: 'Email already in use'
                });
                this.toasterService.error(
                  'It looks like this email address is already in use. Please login to continue using Rally Starter!',
                  'Registration Failed'
                );
              } else {
                this.toasterService.error('Something went wrong. Please try again later.');
              }
            });
        } else {
          this.form.controls.confirmPassword.setErrors({
            custom: 'Passwords do not match'
          });
          this.formLoading = false;
        }
      } else {
        this.authService
          .login(this.form.value.email, this.form.value.password)
          .then((res) => {
            this.userService.createOrUpdateUser(res);
            this.dialogRef.close(true);
          })
          .catch(() => {
            this.formLoading = false;
            this.form.setErrors({
              custom: 'Invalid email or password'
            });
            this.showMigrationAlert = true;
            this.toasterService.error('Invalid email and/or password. Please try again.');
          });
      }
    }
  }
}
