import { Component, inject, Input, OnInit } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Rally } from '@shared/interface/rally';
import { AppService } from '@shared/services/app.service';
import { AuthService } from '@shared/services/auth.service';
import { removeHTMLTags, removeURLParams } from '@shared/utils/rally-utils';
import { environment } from 'src/environments/environment';

const windowEmailMessage = `I just took action on this campaign, {{rally_name}}.
Please check it out and join me in taking action!`;

@Component({
  selector: 'app-share-rally-actions',
  templateUrl: './share-rally-actions.component.html',
  styleUrls: ['./share-rally-actions.component.scss'],
  standalone: true
})
export class ShareRallyActionsComponent implements OnInit {
  @Input() public rally: Rally;
  @Input() public size: 'normal' | 'large' = 'normal';
  @Input() public embedUrl: string = '';

  private isWindows = false;

  public appService: AppService = inject(AppService);
  private analytics: Analytics = inject(Analytics);
  private authService: AuthService = inject(AuthService);

  public constructor() {
    this.appService.getOS() === 'Windows';
  }

  public ngOnInit(): void {}

  public logEvents(type: string): void {
    if (this.analytics) {
      const email = this.authService.currentUser?.email || this.authService.unauthenticatedUserCache.email || '';
      const name: string =
        this.authService.currentUser?.displayName ||
        `${this.authService.unauthenticatedUserCache.firstName} ${this.authService.unauthenticatedUserCache.lastName}`;

      logEvent(this.analytics, `Rally Shared - ${type.charAt(0).toUpperCase() + type.slice(1)}`, {
        rally_name: this.rally.name,
        action_type: type.charAt(0).toUpperCase() + type.slice(1),
        user_id: this.authService.currentUserUID || '',
        email: email || '',
        first_name: name?.split(' ')[0] || '',
        last_name: name?.split(' ').splice(1).join(' ') || '',
        url: `${environment.siteURL}/rally/${this.rally.id}/${this.rally.slug}`
      });
    }
  }

  public getShareURL(type: string): string {
    const locationHref = removeURLParams(window.location.href);
    const emailBody = this.isWindows ? windowEmailMessage.replace('{{rally_name}}', this.rally.name) : removeHTMLTags(this.rally.description + '\n\n');

    switch (type) {
      case 'email':
        return `mailto:?subject=${encodeURIComponent(this.rally.name)}&body=${encodeURIComponent(emailBody)}%20${encodeURIComponent(locationHref)}`;
      case 'facebook':
        return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(locationHref);
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(locationHref)}`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.rally.name)}%20%23letsrally&url=${encodeURIComponent(locationHref)}`;
    }

    return '';
  }
}
