import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { FacebookAuthProvider, OAuthCredential, UserCredential } from '@angular/fire/auth';
import {
  collection,
  collectionData,
  doc,
  docData,
  DocumentReference,
  Firestore,
  Query,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Response } from '@shared/interface/common';
import { ActionsTaken, UserSubscription } from '@shared/interface/rally';
import { Address, MailchimpList, UnauthenticatedUser, User } from '@shared/interface/user';
import { ToastrService } from 'ngx-toastr';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private analytics = inject(Analytics);

  public constructor(
    private firestore: Firestore,
    private fireFn: Functions,
    private authService: AuthService,
    private http: HttpClient,
    private toasterService: ToastrService
  ) {}

  public createOrUpdateUser(user: UserCredential, data?: Partial<User>): void {
    const docRef = doc(this.firestore, 'users', user.user.uid);

    let photo: string | null = null;
    if (user.user.photoURL) {
      let token: OAuthCredential | null = null;
      if (user.providerId === 'facebook.com') {
        token = FacebookAuthProvider.credentialFromResult(user);
      }
      switch (user.providerId) {
        case 'google.com':
          photo = user.user.photoURL.replace('s96-c', 's400-c');
          break;
        case 'twitter.com':
          photo = user.user.photoURL.replace('__normal', '__400x400');
          break;
        case 'facebook.com':
          photo = `${user.user.photoURL}?height=400&width=400&access_token=${token?.accessToken}`;
          break;
        default:
          photo = user.user.photoURL;
      }
    }

    const userData: Partial<User> = {
      name: user.user?.displayName as string,
      email: (user.user?.providerId === 'twitter.com' ? user.user?.providerData[0]?.email : user.user?.email) as string,
      metadata: {
        lastLogin: new Date(user.user?.metadata.lastSignInTime as string),
        createdAt: new Date(user.user?.metadata.creationTime as string)
      },
      photo,
      providerId: user.user?.providerData[0]?.providerId,
      ...data
    };

    // If creation time and last login time is within 1 minutes, then this is a new user
    if (userData.metadata?.createdAt.getTime() === userData.metadata?.lastLogin.getTime()) {
      if (this.analytics) {
        logEvent(this.analytics, 'User Created', {
          method: user.user?.providerData[0]?.providerId,
          email: userData.email,
          user: this.authService.currentUserUID || ''
        });
      }
    }

    setDoc(docRef, userData, {
      merge: true
    });
  }

  public getAllUsers(queryConstraints: QueryConstraint[] = []): Observable<User[]> {
    const queryRef = query(collection(this.firestore, 'users'), ...queryConstraints) as Query<User>;
    return collectionData(queryRef, { idField: 'id' });
  }

  public getUserByID(id: string): Observable<User> {
    const docRef = doc(this.firestore, 'users', id) as DocumentReference<User>;
    return docData(docRef, { idField: 'id' }) as Observable<User>;
  }

  public getUsersByIDs(ids: string[]): Observable<User[]> {
    const queryRef = query(collection(this.firestore, 'users'), where('id', 'in', ids)) as Query<User>;
    return collectionData(queryRef, { idField: 'id' });
  }

  public updateUser(id: string, user: Partial<User>): Promise<void> {
    const docRef = doc(this.firestore, 'users', id) as DocumentReference<User>;
    return updateDoc(docRef, user);
  }

  public updateUserRepresentatives(address: string): Observable<Response> {
    return httpsCallableData<{ address: string }, Response>(this.fireFn, 'updateUserRepresentatives')({ address });
  }

  public getUserRepresentatives(address: string): Observable<Response> {
    return httpsCallableData<{ address: string }, Response>(this.fireFn, 'getUserRepresentatives')({ address });
  }

  public getRepresentativesByName(name: string, chamber: 'house' | 'senate'): Observable<Response> {
    return httpsCallableData<{ name: string; chamber: string }, Response>(this.fireFn, 'getRepresentativeByName')({ name, chamber });
  }

  public updateSessionUserDataFromSubs(subsData: UserSubscription) {
    this.authService.unauthenticatedUserCache.firstName = subsData.firstName;
    this.authService.unauthenticatedUserCache.lastName = subsData.lastName;
    this.authService.unauthenticatedUserCache.email = subsData.email;
    if (this.authService.unauthenticatedUserCache.address) {
      this.authService.unauthenticatedUserCache.address.zipcode = subsData.zipcode;
    } else {
      this.authService.unauthenticatedUserCache.address = { zipcode: subsData.zipcode };
    }

    this.authService.unauthenticatedUser.next(this.authService.unauthenticatedUserCache);
    try {
      sessionStorage.setItem('unauthenticatedUser', JSON.stringify(this.authService.unauthenticatedUserCache));
    } catch (e) {
      console.error(e);
    }
  }

  public updateSessionUserData(userData: UnauthenticatedUser) {
    this.authService.unauthenticatedUser.next(userData);
    try {
      sessionStorage.setItem('unauthenticatedUser', JSON.stringify(userData));
    } catch (e) {
      console.error(e);
    }
  }

  public storeActionsToSession(docId: string, actionTaken: ActionsTaken): void {
    if (this.authService.unauthenticatedUserCache.actionsTaken[docId]) {
      this.authService.unauthenticatedUserCache.actionsTaken[docId].push(actionTaken);
    } else {
      this.authService.unauthenticatedUserCache.actionsTaken[docId] = [actionTaken];
    }

    this.authService.unauthenticatedUser.next(this.authService.unauthenticatedUserCache);
    try {
      sessionStorage.setItem('unauthenticatedUser', JSON.stringify(this.authService.unauthenticatedUserCache));
    } catch (e) {
      console.error(e);
    }
  }

  public storeSubscriptionToSession(docId: string, sub: UserSubscription, type: 'subscriptions' | 'petitions'): void {
    if (this.authService.unauthenticatedUserCache[type][docId]) {
      this.authService.unauthenticatedUserCache[type][docId].push(sub);
    } else {
      this.authService.unauthenticatedUserCache[type][docId] = [sub];
    }

    this.authService.unauthenticatedUser.next(this.authService.unauthenticatedUserCache);
    try {
      sessionStorage.setItem('unauthenticatedUser', JSON.stringify(this.authService.unauthenticatedUserCache));
    } catch (e) {
      console.error(e);
    }
  }

  public onAuthorizeMailchimp(): Observable<Response> {
    return this.http.post<Response>(`${environment.api}/authorizeMailchimp`, {});
  }

  public getMailchimpLists(): Observable<MailchimpList> {
    return httpsCallableData(this.fireFn, 'getMailchimpLists')() as Observable<MailchimpList>;
  }

  public onToggleSuperAdmin(id: string): Observable<Response> {
    return httpsCallableData<{ id: string; role: string }, Response>(this.fireFn, 'toggleSuperAdmin')({ id, role: 'super-admin' }) as Observable<Response>;
  }

  public resyncUsersToAlgolia(): Observable<{ code: number }> {
    return httpsCallableData<undefined, { code: number }>(this.fireFn, 'resyncUsersToAlgolia')();
  }

  public getUserFromToken(token: string): Observable<Response> {
    return httpsCallableData<{ token: string }, Response>(this.fireFn, 'getUserFromToken')({ token });
  }

  public unsubscribeFromToken(token: string, user: Partial<User>): Observable<Response> {
    return httpsCallableData<{ token: string; user: Partial<User> }, Response>(this.fireFn, 'unsubscribeFromToken')({ token, user });
  }

  public updateOrFetchReps(formValue: any, userAddress: Address, cb: (success: boolean) => void): void {
    const address: string = `${userAddress.address}`;

    // the user is logged in so we'll use the update reps Endpoint
    if (this.authService.currentUserUID) {
      this.authService.currentUser.firstName = formValue.firstName;
      this.authService.currentUser.lastName = formValue.lastName;
      this.authService.currentUser.email = formValue.email;
      this.authService.currentUser.phone = formValue.phone;

      // call the update/fetch representatives
      this.updateUserRepresentatives(address)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.status === 200) {
            this.authService.fetchUserDataFromFB();
            this.authService.currentUser.address = userAddress;

            this.updateUser(this.authService.currentUserUID || '', { address: userAddress, phone: formValue.phone }).then(() => {
              this.authService.currentUser.address = userAddress;
              cb(true);
            });
          } else {
            cb(false);
            this.toasterService.error(res.message);
          }
        });
    } else {
      this.authService.unauthenticatedUserCache.firstName = formValue.firstName;
      this.authService.unauthenticatedUserCache.lastName = formValue.lastName;
      this.authService.unauthenticatedUserCache.email = formValue.email;
      this.authService.unauthenticatedUserCache.phone = formValue.phone;
      this.authService.unauthenticatedUserCache.address = userAddress;

      this.getUserRepresentatives(address)
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res.status === 200) {
            this.authService.unauthenticatedUserCache.address = userAddress;
            this.authService.unauthenticatedUserCache.representatives = res.data;
            this.updateSessionUserData(this.authService.unauthenticatedUserCache);
            cb(true);
          } else {
            cb(false);
            this.toasterService.error(res.message);
          }
        });
    }
  }
}
